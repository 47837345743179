<template>
  <div class="info-body">
    <div class="tag"></div>
    <div class="info" v-if="!tag">
      <div class="item" @click="goDetail('/travelService')">
        <img src="@/image/home/1.png" />
      </div>
      <div class="item" @click="goDetail('/travelManage')">
        <img src="@/image/home/2.png" />
      </div>
      <div class="item" @click="goDetail('/travelMarket')">
        <img src="@/image/home/3.png" />
      </div>
    </div>
    <div class="info1" v-else>
      <div class="top">
        <div class="top-item"><img src="@/image/home/yougansu.png" /></div>
        <div class="top-item"><img src="@/image/home/dashuju-2.png" /></div>
      </div>
      <div class="bottom">
        <div class="bottom-item" @click="goDetail('/travelService')">
          <img src="@/image/home/bottom1.png" />
        </div>
        <div class="bottom-item" @click="goDetail('/travelManage')">
          <img src="@/image/home/bottom2.png" />
        </div>
        <div class="bottom-item" @click="goDetail('/travelMarket')">
          <img src="@/image/home/bottom3.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { videoPlayer } from 'vue-video-player'
import "video.js/dist/video-js.css";
export default {
  name: "index",
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      tag: false,
    };
  },
  watch: {},
  mounted() {},
  methods: {
    goDetail: function (route) {
      this.$router.push({ path: route });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
}
.info-body {
  height: 100vh;
  width: 100vw;
  background: url(../../image/home/bg.png) no-repeat center;
  background-size: 100% 100%;
  padding: 2.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5vw;
  // height: 55vh;
  margin-top: 15vh;
  background: transparent;

  .info img {
    border-radius: 50px;
  }
  .item {
    height: 100%;
    border-radius: 50px;
    cursor: pointer;
    width: 30%;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.5);
  }
}

.item:hover {
  // flex: 1;
  cursor: pointer;
  animation: pulse;
  animation-duration: 3s;
}
.info1 {
  background: #fff;
  width: 92%;
  height: 65%;
  margin-top: 25vh;
  background: url(../../image/home/infoBg.png) no-repeat center;
  background-size: 100% 100%;
  // border-radius: 24px;
  .top,
  .bottom {
    display: flex;
    justify-content: space-between;
  }
  .top {
    margin-top: -6vh;
    padding: 0 4vw;
  }
  .top-item {
    width: 49%;
  }
  .bottom {
    margin-top: 5vh;
    padding: 0 4vw;
    height: 41vh;
  }
  .bottom-item {
    width: 32%;
    border-radius: 50%;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.5);
  }
  .bottom-item:hover {
    cursor: pointer;
    animation: pulse;
    animation-duration: 3s;
  }
}
.tag {
  position: fixed;
  width: 100px;
  height: 100px;
  // background: #fff;
  top: 5vh;
  z-index: 999;
}
</style>
